import App from "../../core/facade";
import mediator from "libs/mediator";

App.on("campaigns_show:init", function() {
  App.log("Campaign page init");
  App.CouponFilters();
  App.Widgets();
  App.HeroCountdown();
  App.Vote();
  App.Categories();
  mediator.trigger("showmore");
});

App.on("campaigns_sem:init", function() {
  App.log("Campaign sem page init");
  App.Widgets();
  mediator.trigger("showmore");
});
